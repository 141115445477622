<template>
	<div class="scheme">
		<img src="../../../assets/imges/index/3.png" class="headerimg" />
		<div class="merit">
			<div class="header">
				<div class="text">高效直播 稳定录制</div>
			</div>
			<div class="schemeinfo">
				<div class="info">
					<div class="headerinfo">
						<img src="../../../assets/culture/6.png" />
						<div  class="text">
							多格式录制、多场景使用：依托于邦伲德直播的高校性能服务集群，实现多录制方案处理，
							在直播结束后获得HLS、FLV、MP4、AAC等多种格式的录制文件，方便快速进行回访的处理和分发
						</div>
					</div>
					<div class="centerinfo">
						<img src="../../../assets/culture/6.png" />
						<div  class="text">
							强大的点播媒资处理能力：强得力于云点播强大的媒资管理和灵活的视频处理能力，可以对回放视频批量进行编辑、转封装，转码等处理，为客户提供全方位的点播媒资处理和分发能力 
						</div>
					</div>
					<div>
						<img src="../../../assets/culture/6.png" />
						<div  class="text">
							更优惠的录制计费模式：录制功能按月有效天数计费，有效降低录制成本，解决本地录制管理不变的问题，给消费者绝佳的消费体验。
						</div>
					</div>
				</div>
				<div class="schemeimg">
					<img src="../../../assets/scheme/1.png" />
				</div>
			</div>
		</div>
		
		<div class="function">
			<div class="header">
				<div class="text">方案功能性</div>
			</div>
			<div class="functioninfo">
				<div class="info">
					<img src="../../../assets/scheme/icon1.png" />
					<div class="functioninfotitle">云端录制分发</div>
					<div class="text">
						提供从直播录制到点播制作到内容分发的解决方案，助力客户快速拥有直播录制分发的能力
					</div>
				</div>
				<div class="info">
					<img src="../../../assets/scheme/icon2.png" />
					<div class="functioninfotitle">灵活的录制方式</div>
					<div class="text">
						提供从直播录制到点播制作到内容分发的解决方案，助力客户快速拥有直播录制分发的能力
					</div>
				</div>
				<div class="info">
					<img src="../../../assets/scheme/icon3.png" />
					<div class="functioninfotitle">多样式的录制参数</div>
					<div class="text">
						提供从直播录制到点播制作到内容分发的解决方案，助力客户快速拥有直播录制分发的能力
					</div>
				</div>
				<div class="info">
					<img src="../../../assets/scheme/icon4.png" />
					<div class="functioninfotitle">二次制作和分发能力</div>
					<div class="text">
						提供从直播录制到点播制作到内容分发的解决方案，助力客户快速拥有直播录制分发的能力
					</div>
				</div>
			</div>
		</div>
		
		<div class="transcribe">
			<div class="header">
				<div class="text">直播录制解决方案</div>
			</div>
			<div class="transcribeinfo">
				<div class="transcribeimg">
					<img src="../../../assets/scheme/3.png" />
				</div>
				<div class="info">
					<div class="info2">
						<img src="../../../assets/culture/6.png" />
						<div style="display: inline-block;" class="info3">
							<div class="infoheader">方案介绍</div>
							<div class="infotext">为音视频行业客户大招一体化的云端录制，内容二次制作和视频分发的全链路方案</div>
						</div>
					</div>
					<div class="info2 info2center">
						<img src="../../../assets/culture/6.png" />
						<div style="display: inline-block;" class="info3">
							<div class="infoheader">使用流程</div>
							<div class="infotext">注册5G直播点播，开通即可进行使用</div>
						</div>
					</div>
					<div class="info2">
						<img src="../../../assets/culture/6.png" />
						<div style="display: inline-block;" class="info3">
							<div class="infoheader">方案优势</div>
							<div class="infotext">提供手机直播、PC直播、视频采集设备，提供多样的推流服务，有效降低客户推流门槛，提高直播可用性</div>
							<div class="infotext">强大的视频加速能力，节点高质量加速分发，保证直播，回放视频无卡顿，顺畅无延迟</div>
							<div class="infotext">多种录制方式，提供更灵活多用的录制场景</div>
							<div class="infotext">基于邦伲德强大的语音视频处理能力，整合多终端播放，配合内容生态，是用户内容高效，稳定的触达用户。</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="apply">
			<div class="applycontent">
				<div class="header">
					应用场景
				</div>
				<div class="applylist">
					<div class="applycard">
						<img src="../../../assets/scheme/4.png" />
						<div class="applyheader">电商视频</div>
						<div class="applyinfo">
							助力电商平台发展，使用上架更加全面的传递了商品信息，促进用户的有效政策，井底运营成本，增加成交量。
						</div>
					</div>
					<div class="applycard">
						<img src="../../../assets/scheme/5.png" />
						<div class="applyheader">在线教育</div>
						<div class="applyinfo">
							便捷的控制平台和丰富的API、配合超级播放器、防盗链等安全机制，保护精心制作的教学内容万无一失，方便快捷
						</div>
					</div>
					<div class="applycard">
						<img src="../../../assets/scheme/6.png" />
						<div class="applyheader">广电媒体</div>
						<div class="applyinfo">
							助力传统媒体向互联网媒体的低门槛转型提供视频拆条、视频转码和剪辑，有效增加制作效率，能够更好的快速传递
						</div>
					</div>
					<div class="applycard">
						<img src="../../../assets/scheme/7.png" />
						<div class="applyheader">游戏直播</div>
						<div class="applyinfo">
							移动直播SDK和直播服务互通可帮助游戏直播平台快速建立直播能力，保证食品直播低延时，无卡顿，能够同步
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="case">
			<div class="header">
				客户案例
			</div>
			<div class="caselist">
				<div class="casecard">
					<img src="../../../assets/5Gstreaming/blue4.png" />
					<div class="title">多用户端口</div>
					<div class="info">
						可打通APP、小程序、H5、公众号等端口，灵活布控市场
					</div>
					<div class="bebottom"></div>
				</div>
				<div class="casecard">
					<img src="../../../assets/5Gstreaming/blue4.png" />
					<div class="title">多用户端口</div>
					<div class="info">
						可打通APP、小程序、H5、公众号等端口，灵活布控市场
					</div>
					<div class="bebottom"></div>
				</div>
				<div class="casecard">
					<img src="../../../assets/5Gstreaming/blue4.png" />
					<div class="title">多用户端口</div>
					<div class="info">
						可打通APP、小程序、H5、公众号等端口，灵活布控市场
					</div>
					<div class="bebottom"></div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		}
	}
</script>

<style scoped lang="less">
	.merit {
		width: 80%;
		margin: 0 auto;

		.schemeinfo {
			margin-top: 86px;
			padding-bottom: 62px;
			display: flex;
			justify-content: space-between;
			.info {
				.headerinfo{
					margin-top: 82px;
				}
				.centerinfo{
					margin: 40px 0;
				}
				.text{
					display: inline-block;
					width: 717px;
					font-size: 14px;
					font-family: Medium;
					font-weight: 500;
					color: #9B9C9D;
					line-height: 28px;
					margin-left: 13px;
					vertical-align: top;
				}
				img {
					width: 14px;
					line-height: 28px;
				}
			}

			.schemeimg img {
				width: 531px;
				height: 523px;
			}
		}
	}
	
	.function{
		height: 554px;
		background-image: url(../../../assets/scheme/2.png);
		background-size: cover;
		.functioninfo{
			width: 80%;
			margin: 0 auto;
			margin-top: 81px;
			display: flex;
			justify-content: space-between;
			.info{
				width: 285px;
				.functioninfotitle{
					margin-top: 41px;
					font-size: 24px;
					font-family: Medium;
					font-weight: 500;
					line-height: 36px;
				}
				.text{
					font-size: 16px;
					font-family: Medium;
					font-weight: 500;
					color: #9B9C9D;
					line-height: 30px;
					margin-top: 28px;
				}
			}
		}
	}
	
	.transcribeinfo{
		width: 80%;
		margin: 0 auto;
		margin-top: 64px;
		padding-bottom: 107px;
		display: flex;
		align-items: center;
		justify-content: center;
		.transcribeimg img{
			width: 893px;
			height: 951px;
		}
		.info{
			margin-left: 80px;
			.info2{
				img{
					width: 14px;
					vertical-align: top;
					margin-top: 12px;
				}
				.info3{
					margin-left: 10px;
					.infoheader{
						font-size: 24px;
						font-family: Medium;
						font-weight: 500;
						line-height: 36px;
					}
					.infotext{
						margin-top: 12px;
						font-size: 16px;
						font-family: Medium;
						font-weight: 500;
						color: #9B9C9D;
						line-height: 30px;
						width: 417px;
					}
				}
				
			}
			
			.info2center{
				margin-top:31px ;
				margin-bottom: 44px;
			}
		}
	}
	
	.apply{
		background-color: #F5F5F9;
		.applycontent{
			width: 80%;
			margin: 0 auto;
			.applylist{
				margin-top: 64px;
				padding-bottom: 113px;
				display: flex;
				justify-content: space-between;
				.applycard{
					.applyheader{
						font-size: 24px;
						font-family: Medium;
						font-weight: 500;
						margin-top: 37px;
					}
					.applyinfo{
						margin-top: 12px;
						width: 303px;
						font-size: 20px;
						font-family: Medium;
						font-weight: 500;
						color: #9E9E9E;
						line-height: 32px;
					}
					img{
						width: 324px;
						height: 210px;
					}
				}
			}
		}
	}
	
	.case{
		width: 80%;
		margin: 0 auto;
		padding-bottom: 94px;
		.caselist{
			margin-top: 109px;
			display: flex;
			justify-content: space-between;
			.casecard:hover{
				.bebottom{
					border-radius: 0 0 20px 20px;
					width: 338px;
					height: 17px;
					position: relative;
					margin-top: 15px;
					margin-left: -34px;
					background-color: #2F7FFC;
				}
			}
			.casecard{
					width: 262px;
					height: 156px;
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
					border-radius: 10px;
					border: 1px solid #E8E8E8;
					padding: 45px 84px;
					text-align: center;
					.title{
						font-size: 24px;
						font-family: Medium;
						font-weight: 500;
						margin-top: 31px;
					}
					.info{
						font-size: 20px;
						font-family: Medium;
						font-weight: 500;
						color: #9E9E9E;
						margin-top: 14px;
					}
					img{
						margin-top: -840px;
						position: relative;
						width: 88px;
				}
			}
		}
	}

	.header {
		text-align: center;
		font-size: 36px;
		font-family: Medium;
		font-weight: 500;
		padding-top: 92px;
	}
</style>
